import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`These animations are reimaginings, not “reconstructions” based on Welles’s working notes on blocking, composition, and set design for the live action film he wanted to make. He made the drawings on the first version of the script, although the six sets of drawings which we chose to animate correspond to scenes that are also present in later versions, including the last known surviving draft of the screenplay, dated December 20, 1939, which guided our creative decisions about the visual and aural contents of these animations and inspired the addition of a few visual elements that are not part of Welles’s original drawings. Voice actors performed the dialogue as written, and in the manner sometimes indicated, in the final version of the script, although we also took cues on tone and pacing from recordings of Welles’s two broadcast radio versions of "Heart of Darkness" (1938 and 1945). `}</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Animated Reimaginings","key":"the-animation-process","parentKey":"animations","sortOrder":0};

  